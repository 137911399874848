import React from "react";
import {Routes, Route} from 'react-router-dom'
import {HomePage} from "./pages/HomePage";
import {BlogPage} from "./pages/BlogPage";
import {GalleryPage} from "./pages/GalleryPage";
import {AboutPage} from "./pages/AboutPage";
import {Layout} from "./components/core/Layout";
import {NotFoundErrorPage} from "./pages/NotFoundErrorPage";
import {SingleTourPage} from "./pages/SingleTourPage";
import {SingleBlogPage} from "./pages/SingleBlogPage";
import {ToursPage} from "./pages/ToursPage";
import {WinterSingleTourPage} from "./pages/NewYearPage";

class App extends React.Component {
  render() {
    return (
        <>
          <Routes>
            <Route path='/' element={<Layout/>}>
              <Route index element={<HomePage/>}/>
              {/*<Route path='/tours' element={<ToursPage/>}/>*/}
              {/*<Route path='/tour_page' element={<SingleTourPage/>}/>*/}
              {/*<Route path='/blog' element={<BlogPage/>}/>*/}
              {/*<Route path='/blog_page' element={<SingleBlogPage/>}/>*/}
              {/*<Route path='/gallery' element={<GalleryPage/>}/>*/}
              <Route path='/about' element={<AboutPage/>}/>
              <Route path='/new_year' element={<WinterSingleTourPage/>}/>
              <Route path='*' element={<NotFoundErrorPage/>}/>
            </Route>
          </Routes>

        </>
    )
  }
}

export default App;
