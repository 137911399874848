import React from "react";
import axios from "axios";

class BlogPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'blogs': [],
        };
    }

    componentDidMount() {
        axios.get('https://ever-green.club/api/blogs/')
            .then(res => {
                this.setState({blogs: res.data})
                console.log(res.data)
            })
            .catch(err => {
                console.log(err)
            });
    }

    render() {
        const divs = []
        const blogs = this.state['blogs']
        for (let blog of blogs){
            divs.push(
                BlogItem(blog['id'], blog['title'], blog['summary'], blog['main_image'])
            )
        }
        return(
            <>
                <section className="section">
                    <div className="blog-margin">
                        <div>
                            <div className="blog-catalogue">
                                {divs}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


function BlogItem(id, title, summary, main_image){
    return (
        <div className="blog-catalogue-item">
            <div className="link-blog-img">
                <a href={`blog_page?id=${id}`} className="blog-link">
                    <img src={"data:image/png;base64," + main_image}/>
                    <h3 className="blog-heading">{title}</h3>
                </a>
            </div>
            <div>
                <p className="blog-paragraph">{summary}</p>
            </div>
        </div>
    )
}

export {BlogPage};
