import React from "react";
import galyImage from "../assets/img/galy-image.png";
import dmitryImage from "../assets/img/dmitry-image.png";
import victoriaImage from "../assets/img/victoria-image.png";

class AboutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'prevImg': 0,
        };
    }

    handleClick = event => {
        let currId = event.currentTarget.id
        let currNumber = currId.match(/\d/)[0]

        if (currNumber !== this.state.prevImg){
            document.querySelector('#about-img-'+this.state.prevImg).style.display = 'none'
            document.querySelector('#about-team-link-'+this.state.prevImg).classList.remove('about-team-current')

            document.querySelector('#about-img-'+currNumber).style.display = ''
            event.currentTarget.classList.add('about-team-current')

            this.state.prevImg = currNumber
        }
    }

    render() {
        return (
            <>
                <div className="hero-section hero-big">
                    <div className="hero-container">
                        <div className="hero-title">
                            <h1 className="hero-content">Клуб Вечнозелёных</h1>
                            <p className="hero-sub-content">Организация водных походов с 2006 г.</p>
                        </div>
                    </div>
                </div>
                <section className="about-section">
                    <div className="about-global-padding">
                        <div className="about-content-container">
                            <div className="about-team">
                                <div className="about-team-text">
                                    <a id="about-team-link-0" className="about-description-item about-team-current"
                                       onClick={this.handleClick}>
                                        <h3 className="about-description-heading">
                                            Гали Ратуш
                                        </h3>
                                        <div className="about-description-spacing"></div>
                                        <p className="about-description-paragraph">
                                            <strong>
                                                Гали Ратуш. Организатор и руководитель походов. Общая организация и маршруты.
                                                <br/>
                                                Контакты: +7 (9ОЗ) 281-758З; galy-edit@rambler.ru
                                            </strong>
                                        </p>
                                    </a>
                                    <a id="about-team-link-1" className="about-description-item"
                                       onClick={this.handleClick}>
                                        <h3 className="about-description-heading">
                                            Дмитрий Ратуш
                                        </h3>
                                        <div className="about-description-spacing"></div>
                                        <p className="about-description-paragraph">
                                            <strong>
                                                Дмитрий Ратуш. Логистика и техническое обеспечение процесса.
                                                <br/>
                                                Контакты: +7(9I6) 97I-OO-75
                                            </strong>
                                        </p>
                                    </a>
                                    <a id="about-team-link-2" className="about-description-item"
                                       onClick={this.handleClick}>
                                        <h3 className="about-description-heading">
                                            Виктория Сушко
                                        </h3>
                                        <div className="about-description-spacing"></div>
                                        <p className="about-description-paragraph">
                                            <strong>
                                                Виктория Сушко. Завхоз. Раскладка и питание.
                                            </strong>
                                        </p>
                                    </a>
                                </div>
                                <div className="about-team-images">
                                    <div id="about-img-0" className="about_image">
                                        <div>
                                            <img src={galyImage}/>
                                        </div>
                                    </div>
                                    <div id="about-img-1" style={{display: "none"}} className="about_image">
                                        <div>
                                            <img src={dmitryImage}/>
                                        </div>
                                    </div>
                                    <div id="about-img-2" style={{display: "none"}} className="about_image">
                                        <div>
                                            <img src={victoriaImage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about-history margin-on-top">
                                <h1 className="about-head">История клуба</h1>
                                <div className="about-text">
                                    <p className="paragraph-medium">
                                        Первый поход, организованный Гали Ратуш самостоятельно, в одиночку, состоялся в 2006
                                        году по озеру Селигер. В собственности тогда не было никакого снаряжения, для
                                        передвижения по воде на турбазе арендовались гребные лодки. В походы приходили
                                        друзья и знакомые, а также знакомые знакомых.
                                        Через пару лет сформировался костяк, который ходил в походы каждый или почти каждый
                                        год, и родилось название "Клуб Вечнозеленых поклонников Селигера". Так продолжалось
                                        еще несколько лет, пока Гали не опробовала в качестве средства передвижения
                                        разборную байдарку Таймень.. и влюбилась!
                                    </p>
                                    <p className="paragraph-medium">
                                        Постепенно начали приобретаться всё новые и новые байдарки, и заменять собой
                                        арендные лодки в походах. Кроме того, разборный мобильный флот позволил
                                        разнообразить маршруты походов, и включить в календарный план сначала сплавы на
                                        майские и июньские праздники, а затем и короткие походы выходного дня.
                                        Из названия Клуба убрали упоминание Селигера, как потерявшее актуальность. Клуб
                                        постоянно развивается, обновляет снаряжение, занимается разведкой новых и уточнением
                                        старых маршрутов, получает обратную связь от туристов и совершенствует формат
                                        работы.
                                    </p>
                                </div>
                                <div className="about-description-divide"/>
                                <p className="paragraph-medium">
                                    Текущее состояние Клуба (2023 год) - это:
                                    <br/>
                                    - 30 посадочных мест в байдарках Таймень -2 и -3
                                    <br/>
                                    - Палатки, спальники, коврики
                                    <br/>
                                    - Полный набор спасжилетов разных размеров, включая детские
                                    <br/>
                                    - Оборудование для костра и походной кухни
                                    <br/>
                                    - Мобильная баня
                                    <br/>
                                    - Собственный транспорт для доставки всего снаряжения и флота
                                    <br/>
                                    - Десятки разведанных маршрутов
                                    <br/>
                                    - Сотни туристов в год
                                    <br/>
                                    - Многолетний опыт работы
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export {AboutPage};