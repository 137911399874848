import {Link} from "react-router-dom";

const GalleryPage = () => {
    return(
        <>
            <section className="section">
                <div className="gallery-margin">
                    <div className="gallery-items">
                        <div className="gallery-item" style={{padding: 10, backgroundColor: "black"}}>
                            <a></a>
                        </div>
                        <div className="gallery-item" style={{padding: 10, backgroundColor: "black"}}>
                            <a></a>
                        </div>
                        <div className="gallery-item" style={{backgroundColor: "black"}}>
                            <a></a>
                        </div>
                        <div className="gallery-item" style={{padding: 10, backgroundColor: "black"}}>
                            <a></a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export {GalleryPage};