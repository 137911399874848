import React from "react";
import axios from "axios";
import ImageGallery from 'react-image-gallery';
import image0 from '../assets/img/winter-gallery8.jpg';
import image01 from '../assets/img/winter-gallery9.jpg';
import image1 from '../assets/img/winter-gallery5.jpg';
import image2 from '../assets/img/winter-gallery6.jpg';
import image3 from '../assets/img/winter-gallery4.jpg';
import image4 from '../assets/img/winter-gallery3.jpg';
import image6 from '../assets/img/winter-gallery1.jpg';

import "react-image-gallery/styles/css/image-gallery.css";

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

class WinterSingleTourPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'tour': {'route_description': ''},
            'images': [
                {
                    original: image0,
                    thumbnail: image0,
                },
                {
                    original: image01,
                    thumbnail: image01,
                },
                {
                    original: image1,
                    thumbnail: image1,
                },
                {
                    original: image2,
                    thumbnail: image2,
                },
                {
                    original: image3,
                    thumbnail: image3,
                },
                {
                    original: image4,
                    thumbnail: image4,
                },
                {
                    original: image6,
                    thumbnail: image6,
                },

            ],
            'name': '',
            'phone': '',
            'email': '',
            'message': '',
        };
    }

    componentDidMount() {
        axios.get('https://ever-green.club/api/wintersingletour/', {})
            .then(res => {
                this.setState({tour: res.data})
            })
            .catch(err => {
                console.log(err)
            });
    }

    handleChange = (e) => {
        this.state[e.target.name] = e.target.value
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        if (12 >= this.state.phone.length && this.state.phone.length >= 11 && !isNaN(this.state.phone)){
            document.querySelector('#submitBtn').innerHTML = 'Отправляем запрос...'
            try {
                await axios.post('https://ever-green.club/api/request/',
                    {
                        'name': this.state.name,
                        'phone': this.state.phone,
                        'email': this.state.email,
                        'message': this.state.message,
                        'tour': 3,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFTOKEN': getCookie('csrftoken'),
                            // Если CSRF-защита включена, добавьте заголовок CSRF-токена
                        }
                    });
            } catch (err) {
            }
            this.formSubmited(e)
        }
        else {
            this.formPhoneCorrection(e)
        }
    }

    formSubmited = (e) => {
        e.target.style.display = 'none'
        document.querySelector('#phone-input').classList.remove('red-border')
        document.querySelector('#phone-format-error').style.display = 'none'
        document.querySelector('#submitBtn').innerHTML = 'Отправить'
        document.querySelector('#afterForm').style.display = ''
    }

    formPhoneCorrection = (e) => {
        document.querySelector('#phone-input').classList.add('red-border')
        document.querySelector('#phone-format-error').style.display = ''
    }

    restoreForm = (e) => {
        document.querySelector('#afterForm').style.display = 'none'
        let form = document.querySelector('#travelForm')
        form.style.display = ''
        Array.from(form.children).map((item, index) => {
            item.children[0].value = ''
        })

    }

    scrollForm = (e) => {
        document.querySelector('form').scrollIntoView({ behavior: "smooth" })
    }

    render() {
        try {
            let tour = this.state.tour
            let tour_object = GetTour(tour)

            let route_description = { __html: `${this.state.tour['route_description']}`}

            return (
                <>
                    <div className="cta-section">
                        <div className="cta-div">
                            <div className="div-block-34">
                                <h2 className="cta-heading center-text">Новогоднее Путешествие 30.12 - 08.01</h2>
                            </div>
                            <a onClick={this.scrollForm}><div className="btn btn-primary">Записаться</div></a></div>
                    </div>

                    <div className="section_margin_tour">
                        <div className="margin_block_desc">
                            <div className="item_frame_desc">
                                <div className="tour_desc_paragraph">
                                    <h2 className="tour-heading">Описание</h2>
                                    <div className="rich-text-block-3 w-richtext">
                                        <div dangerouslySetInnerHTML={route_description}></div>
                                    </div>
                                </div>
                                <div className="tour_desc_image">
                                    <div className="tour-image-gallery-container">
                                        <ImageGallery items={this.state.images}
                                                      showFullscreenButton={false}
                                                      thumbnailPosition="bottom"/>
                                    </div>
                                    <div className="div-block-31">
                                        <div className="div-block-32">
                                            <h4 className="heading-21">31 дек. - 01 янв.</h4>
                                            <h4 className="heading-22-inside">5000</h4>
                                            <div className="text-block-8">&nbsp;Руб.</div>
                                        </div>
                                        <div className="div-block-32">
                                            <h4 className="heading-21">Остальные дни:&nbsp;</h4>
                                            <h4 className="heading-22-inside">4000</h4>
                                            <div className="text-block-8">&nbsp;Руб.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section_margin_tour">
                        <div className="form_container">
                            <div id="reg_poxod" className="form_block">
                                <div id="tour-full-form"
                                     className="form_wrapper">
                                    <div className="card-body p-sm-5 form_color">
                                        <h2 className="text-center mb-4">Записаться</h2>
                                        <form method="post" onSubmit={this.handleSubmit} id="travelForm">
                                            <div className="mb-3">
                                                <input className="form-control" type="text" id="name-2" required
                                                       name="name" onChange={this.handleChange} placeholder="Имя"/>
                                            </div>
                                            <div className="mb-3">
                                                <input className="form-control" type="text" id="phone-input" required
                                                       name="phone" onChange={this.handleChange} placeholder="Телефон"/>
                                                <div id="phone-format-error"
                                                     className="center-text red-text" style={{display: "none"}}>
                                                    Неверный формат номера. Пример: 79991234567
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <input className="form-control" type="email" id="email-2" required
                                                       name="email" onChange={this.handleChange} placeholder="Email"/>
                                            </div>
                                            <div className="mb-3">
                                                        <textarea className="form-control" id="message-2" required
                                                                  name="message" rows="6" onChange={this.handleChange}
                                                                  placeholder="Расскажите о себе: сколько вас, какого числа планируете приехать? Задайте интересующие вопросы и в скором времени мы с вами свяжемся!">
                                                        </textarea>
                                            </div>
                                            <div>
                                                <button id="submitBtn" className="btn btn-primary form-button index-btn"
                                                        type="submit" onClick={this.handleClick}>Отправить
                                                </button>
                                            </div>
                                        </form>
                                        <div id="afterForm" style={{display: 'none'}}>
                                            <div className="form-response">
                                                <h5>Спасибо! В скором времени мы с вами свяжемся!</h5>
                                                <button className="btn btn-primary form-button index-btn" onClick={this.restoreForm}>
                                                    Написать снова
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        } catch (error) {
            return null;
        }

    }
}

export {WinterSingleTourPage}

function GetTour(tour) {
    return new TourObject(
        tour['id'],
        tour['title'],
        tour['route_description'],
        tour['tour_description'],
        tour['route_image'],
    );
}

class TourObject {
    constructor(id, title, route_description, tour_description, route_image) {
        this.id = id;
        this.title = title;
        this.route_description = route_description;
        this.tour_description = tour_description;
        this.route_image = route_image;
    }
}
