import {Link} from "react-router-dom";
import logo from '../../assets/img/logotip.png'
import {LinksIconRow} from "./LinksIconRow";
import {useState} from "react";


const NavBar = () => {
    const [menu, setToggleMenu] = useState(false)

    const toggleNavMenu = () => {
        let navMenu = document.querySelector('#navmenu')
        if (menu){
            navMenu.style.top = '-80px'
            setToggleMenu(false)
        }else{
            navMenu.style.top = '120px'
            setToggleMenu(true)
        }
        console.log(menu)
    }

    return(
        <>
            <section>
                <nav style={{width: "100 %", padding: "0 5%", background: "#dde5b6", zIndex: '2'}}
                     className="navbar navbar-light navbar-expand-md py-3">
                    <div className="container-navbar">
                        <Link className="navbar-logo" to="/">
                            <img src={logo} className="navbar_logo"/>
                        </Link>
                        <button className="navbar-toggle" onClick={toggleNavMenu}>
                            <span className="visually-hidden">Toggle navigation</span>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <nav className="navbar-items navbar-menu">
                            <div className="nav-menu-links"
                                style={{}}>
                                <div className="nav-item-link"><Link className="nav-link active text-center navbar-item"
                                                               to="/">Главная</Link></div>
                                <div className="nav-item-link"><Link className="nav-link active text-center navbar-item"
                                                               to="/tours">Походы</Link></div>
                                <div className="nav-item-link"><Link className="nav-link active text-center navbar-item"
                                                               to="/blog">Статьи</Link></div>
                                <div className="nav-item-link"><Link className="nav-link active text-center navbar-item"
                                                                     style={{whiteSpace: 'nowrap'}}
                                                               to="/about">О нас</Link></div>
                            </div>

                            <LinksIconRow/>

                        </nav>
                    </div>
                </nav>
                <nav className="navbar-dropdown" id="navmenu">
                    <div className="nav-menu-links-drop"
                         style={{}}>
                        <div className="nav-item-link-drop" onClick={toggleNavMenu}>
                            <Link className="nav-link active text-center navbar-item" to="/">Главная</Link>
                        </div>
                        <div className="nav-item-link-drop" onClick={toggleNavMenu}>
                            <Link className="nav-link active text-center navbar-item" to="/tours">Походы</Link>
                        </div>
                        <div className="nav-item-link-drop" onClick={toggleNavMenu}>
                            <Link className="nav-link active text-center navbar-item" to="/blog">Статьи</Link>
                        </div>
                        <div className="nav-item-link-drop" onClick={toggleNavMenu}>
                            <Link className="nav-link active text-center navbar-item" to="/about">О нас</Link>
                        </div>
                    </div>
                    <div className="links-dropdown">
                        <LinksIconRow/>
                    </div>

                </nav>
            </section>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.4.8/swiper-bundle.min.js"></script>
        </>
    )
}

function ToggleDropdown() {
    let menu = document.querySelector('#navmenu')
    console.log(menu)
    menu.style.top = '120px'
}

export {NavBar};