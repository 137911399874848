import {useState} from "react";
import Slider from "./SomeSlider";
import {Link} from "react-router-dom";

const IndexSlider = () => {
    return (
        <section>
            <div className="margin-on-top">
                <div className="simple-slider">
                    <Slider/>
                </div>
                <div className="index_hero_container">
                    <div className="index_hero_grid center-text">
                        <h3>Новогоднее приключение с &quot;Клубом Вечнозелёных&quot;<br/></h3>
                        <p style={{textAlign: "left", width: "100%"}}>Встречаем Новый год и Рождество с настоящей ёлкой,
                            чистым белым снегом в суровой красоте Селигера, скованного льдами.<br/>
                                Новогодняя программа с Дедом Морозом и подарками, праздничный стол на 31 декабря и
                                1 января, а после - экскурсии, лыжные и пешие прогулки, настольные игры, баня.
                        </p>
                        <div className="index_hero_button">
                            <Link to="/new_year" className="btn btn-primary index-btn" type="button">Читать подробнее</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export {IndexSlider}