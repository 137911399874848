import {useState} from "react";
import Slider from "./SomeSlider";
import {Link} from "react-router-dom";

const IndexSlider = () => {
    return (
        <section>
            <div className="margin-on-top">
                <div className="simple-slider">
                    <Slider/>
                </div>
                <div className="index_hero_container">
                    <div className="index_hero_grid">
                        <h4>Походы с &quot;Клубом Вечнозелёных&quot;<br/></h4>
                        <p style={{textAlign: "left", width: "100%"}}><br/>Наша специализация - некатегорийный,
                            облегченный туризм, который максимально подходит для любителей активного, но не
                            экстремального отдыха. <br/>Мы гордимся тем, что ежегодно пополняем ряды туристического
                            сообщества новыми участниками, которые, попробовав однажды сходить с нами в поход,
                            начинают заниматься туризмом и дальше.<br/></p>
                        <div className="index_hero_button">
                            <Link to="/about" className="btn btn-primary index-btn" type="button">Читать подробнее</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export {IndexSlider}