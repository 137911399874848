import React, { useState } from 'react';
import './Slider.css'; // Import CSS for styling
import slide11 from "../../src/assets/img/index_slide_1.png"
import slide12 from "../../src/assets/img/index_slide_2.png"
import slide13 from "../../src/assets/img/index_slide_3.png"

const slides = [
    { id: 1, image: slide11 },
    { id: 2, image: slide12 },
    { id: 3, image: slide13 },
];

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
        console.log('next')
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
        console.log('prev')
    };

    let translateStyle = 'translateX(-'+ (currentSlide * 100) + '%)';
    let css = {
        transform: translateStyle
    }

    return (
        <div className="slider-images">
            <div className="slide-container" style={css}>
                {slides.map((slide) => (
                    <div className="slide" key={slide.id}>
                        <img src={slide.image} alt={'Slide' + slide.id} className="single-slide" />
                    </div>
                ))}
            </div>
            <button className="prev button-slide-images" onClick={prevSlide}>❮</button>
            <button className="next button-slide-images" onClick={nextSlide}>❯</button>
        </div>
    );
};

export default Slider;