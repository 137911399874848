import React, {useEffect} from "react";
import axios from "axios";
import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";

class SingleTourPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'tour': [],
            'images': [],
            'name': '',
            'phone': '',
            'email': '',
            'message': '',
        };
    }

    componentDidMount() {
        const id = new URLSearchParams(window.location.search).get('id')
        axios.get('https://ever-green.club/api/singletour/', {params: {'id': id}})
            .then(res => {
                this.setState({tour: res.data})
                console.log(res.data)
                this.state.images.push({
                    original: "data:image/png;base64," + res.data['route_image'],
                    thumbnail: "data:image/png;base64," + res.data['route_image'],
                })
                for (let image of res.data['gallery']){
                    this.state.images.push({
                        original: "data:image/png;base64," + image,
                        thumbnail: "data:image/png;base64," + image,
                    })
                }
            })
            .catch(err => {
                console.log(err)
            });
    }

    handleChange = (e) => {
        this.state[e.target.name] = e.target.value
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        document.querySelector('#submitBtn').innerHTML = 'Отправляем запрос...'
        try {
            const id = new URLSearchParams(window.location.search).get('id')
            await axios.post('http://localhost:8000/request/',
                {
                    'name': this.state.name,
                    'phone': this.state.phone,
                    'email': this.state.email,
                    'message': this.state.message,
                    'tour': id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        // Если CSRF-защита включена, добавьте заголовок CSRF-токена
                    }
                });
        } catch (err) {
        }
        this.formSubmited(e)
    }

    formSubmited = (e) => {
        e.target.style.display = 'none'
        document.querySelector('#submitBtn').innerHTML = 'Отправить'
        document.querySelector('#afterForm').style.display = ''
    }

    restoreForm = (e) => {
        document.querySelector('#afterForm').style.display = 'none'
        let form = document.querySelector('#travelForm')
        form.style.display = ''
        Array.from(form.children).map((item, index) => {
            item.children[0].value = ''
        })

    }

    scrollForm = (e) => {
        document.querySelector('form').scrollIntoView({ behavior: "smooth" })
    }

    render() {
        try {
            let tour = this.state.tour
            let tour_object = GetTour(tour)

            console.log(this.state.images)

            const tour_description = { __html: `${tour_object.tour_description}`}
            const route_description = { __html: `${tour_object.route_description}`}

            return (
                <>
                    <div className="cta-section">
                        <div className="cta-div">
                            <div className="div-block-34">
                                <h2 className="cta-heading">{tour_object.title}</h2>
                                <div className="div-block-30">
                                    <h3 className="tour-date">20.4.2024</h3>
                                    <div className="text-block-7">-</div>
                                    <h3 className="tour-date">21.4.2024</h3></div>
                            </div>
                            <a onClick={this.scrollForm}><div className="btn btn-primary">Записаться в поход</div></a></div>
                    </div>

                    <div className="section_margin_tour">
                        <div className="margin_block_desc">
                            <div className="item_frame_desc">
                                <div className="tour_desc_paragraph">
                                    <h2 className="tour-heading">О&nbsp;маршруте</h2>
                                    <div className="rich-text-block-3 w-richtext">
                                        <div dangerouslySetInnerHTML={route_description}></div>
                                    </div>
                                </div>
                                <div className="tour_desc_image">
                                    <div className="tour-image-gallery-container">
                                        <ImageGallery items={this.state.images}
                                                      showFullscreenButton={false}
                                                      thumbnailPosition="bottom"/>
                                    </div>
                                    <div className="div-block-31">
                                        <div className="div-block-32">
                                            <h4 className="heading-21">Взрослый:&nbsp;</h4>
                                            <h4 className="heading-22-inside">6000</h4>
                                            <div className="text-block-8">&nbsp;Руб.</div>
                                        </div>
                                        <div className="div-block-33"><h4 className="heading-20">Детский:&nbsp;</h4>
                                            <div>
                                                <h4 className="heading-22-inside-kids">4800</h4>
                                                <h4 className="heading-22-inside">4200</h4>
                                            </div>
                                            <div className="text-block-8">&nbsp;Руб.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section_margin_tour">
                        <div className="margin_block_desc">
                            <div className="grid_about_tour layout_grid">
                                <div id="title_grid_about_tour">
                                    <h2 className="tour-heading">О походе</h2>
                                </div>
                                <div id="content_grid_about_tour">
                                    <div className="content_grid_rich_text">
                                        <div dangerouslySetInnerHTML={tour_description}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section_margin_tour">
                        <div className="form_container">
                            <div id="reg_poxod" className="form_block">
                                <div id="tour-full-form"
                                     className="form_wrapper">
                                    <div className="card-body p-sm-5 form_color">
                                        <h2 className="text-center mb-4">Записаться в поход</h2>
                                        <form method="post" onSubmit={this.handleSubmit} id="travelForm">
                                            <div className="mb-3">
                                                <input className="form-control" type="text" id="name-2" required
                                                       name="name" onChange={this.handleChange} placeholder="Имя"/>
                                            </div>
                                            <div className="mb-3">
                                                <input className="form-control" type="text" id="name-1" required
                                                       name="phone" onChange={this.handleChange} placeholder="Телефон"/>
                                            </div>
                                            <div className="mb-3">
                                                <input className="form-control" type="email" id="email-2" required
                                                       name="email" onChange={this.handleChange} placeholder="Email"/>
                                            </div>
                                            <div className="mb-3">
                                                        <textarea className="form-control" id="message-2" required
                                                                  name="message" rows="6" onChange={this.handleChange}
                                                                  placeholder="Расскажите о себе: сколько вас, какой у вас опыт? Задайте интересующие вопросы и в скором времени мы с вами свяжемся!">
                                                        </textarea>
                                            </div>
                                            <div>
                                                <button id="submitBtn" className="btn btn-primary form-button index-btn"
                                                        type="submit" onClick={this.handleClick}>Отправить
                                                </button>
                                            </div>
                                        </form>
                                        <div id="afterForm" style={{display: 'none'}}>
                                            <div className="form-response">
                                                <h5>Спасибо! В скором времени мы с вами свяжемся!</h5>
                                                <button className="btn btn-primary form-button index-btn" onClick={this.restoreForm}>
                                                    Написать снова
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<form id="wf-form-tour-submission"*/}
                                    {/*      className="contact_form"*/}
                                    {/*      name="wf-form-tour-submission"*/}
                                    {/*      data-name="tour-submission"*/}
                                    {/*      method="get"*/}
                                    {/*      data-wf-page-id="63d14448ec1e970ef4bcc752"*/}
                                    {/*      data-wf-element-id="5d61281f-1161-578f-f57d-5a8db4c2f768"*/}
                                    {/*      aria-label="tour-submission">*/}
                                    {/*    <div className="w-embed">*/}
                                    {/*        <input type="hidden" name="Date Start" value="Apr 20, 2024"/>*/}
                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*        <h2 className="rl-heading-style-h4">Записаться в поход!</h2>*/}
                                    {/*    </div>*/}
                                    {/*    <div id="w-node-_2548cee8-fb48-9f0d-5dba-8963ea6a2f43-f4bcc752"*/}
                                    {/*         className="w-embed">*/}
                                    {/*        <input type="hidden"*/}
                                    {/*               name="tour"*/}
                                    {/*               value="Река Поля, Московская область."/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="rl_contact1_form-field-wrapper">*/}
                                    {/*        <label htmlFor="Tour-Name"*/}
                                    {/*               className="rl-field-label">ФИО</label>*/}
                                    {/*        <input*/}
                                    {/*            className="rl-form-input w-input" maxLength="256" name="Tour-Name"*/}
                                    {/*            data-name="Tour Name"*/}
                                    {/*            placeholder="" type="text" id="Tour-Name" required=""/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="rl_contact1_form-field-wrapper">*/}
                                    {/*        <label htmlFor="Tour-phone"*/}
                                    {/*               className="rl-field-label">Телефон</label>*/}
                                    {/*        <input*/}
                                    {/*            className="rl-form-input w-input" maxLength="256" name="Tour-phone"*/}
                                    {/*            data-name="Tour phone"*/}
                                    {/*            placeholder="" type="text" id="Tour-phone" required=""/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="rl_contact1_form-field-wrapper">*/}
                                    {/*        <label htmlFor="tour-email"*/}
                                    {/*               className="rl-field-label">Email</label>*/}
                                    {/*        <input*/}
                                    {/*            className="rl-form-input w-input" maxLength="256" name="tour-email"*/}
                                    {/*            data-name="tour email"*/}
                                    {/*            placeholder="" type="email" id="tour-email" required=""/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="div-block-28">*/}
                                    {/*        <label htmlFor="people-tour-2"*/}
                                    {/*               className="rl-field-label">Количество*/}
                                    {/*            путевок</label>*/}
                                    {/*        <select id="people-tour-2" name="people-tour"*/}
                                    {/*                data-name="people tour"*/}
                                    {/*                className="select-field w-select">*/}
                                    {/*            <option value="1" selected="selected">1</option>*/}
                                    {/*            <option value="2">2</option>*/}
                                    {/*            <option value="3">3</option>*/}
                                    {/*            <option value="4">4</option>*/}
                                    {/*            <option value="more">Больше (Укажите в комментарии)</option>*/}
                                    {/*        </select>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="div-block-28">*/}
                                    {/*        <h5 className="heading-14"></h5>*/}
                                    {/*        <p className="paragraph-5"><br/></p>*/}
                                    {/*        <div className="div-block-29">*/}
                                    {/*            <label className="w-checkbox">*/}
                                    {/*                <div*/}
                                    {/*                    className="w-checkbox-input w-checkbox-input--inputType-custom form-check"></div>*/}
                                    {/*                <input id="rent-tour" type="checkbox" name="rent-tour"*/}
                                    {/*                       data-name="rent tour"*/}
                                    {/*                       style={{opacity: "0", position: "absolute", zIndex: "-1"}}/>*/}
                                    {/*                <span*/}
                                    {/*                    className="checkbox-label w-form-label"*/}
                                    {/*                    htmlFor="rent-tour">Нужна аренда снаряжения</span>*/}
                                    {/*            </label>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="rl_contact1_form-field-wrapper">*/}
                                    {/*        <label htmlFor="tour-message"*/}
                                    {/*               className="rl-field-label">Сообщение</label>*/}
                                    {/*        <textarea*/}
                                    {/*            id="tour-message" name="tour-message" maxLength="5000"*/}
                                    {/*            data-name="tour message"*/}
                                    {/*            placeholder="Расскажите о себе, задайте интересующие вопросы..."*/}
                                    {/*            required=""*/}
                                    {/*            className="rl-form-text-area w-input"></textarea>*/}
                                    {/*    </div>*/}
                                    {/*    <div id="w-node-_5d61281f-1161-578f-f57d-5a8db4c2f77b-f4bcc752"*/}
                                    {/*         className="rl_contact1_button-wrapper">*/}
                                    {/*        <div className="rl_contact1_spacing-block-4"></div>*/}
                                    {/*        <input type="submit" data-wait="Пожалуйста подождите..."*/}
                                    {/*               id="w-node-_5d61281f-1161-578f-f57d-5a8db4c2f77d-f4bcc752"*/}
                                    {/*               className="rl-button w-button"*/}
                                    {/*               value="Отправить"/>*/}
                                    {/*    </div>*/}
                                    {/*</form>*/}
                                    {/*<div className="rl-success-message w-form-done" tabIndex="-1" role="region"*/}
                                    {/*     aria-label="tour-submission success">*/}
                                    {/*    <div className="rl-success-text">Cпасибо за заявку! Мы свяжемся с вами для*/}
                                    {/*        уточнения и оплаты,&nbsp;ожидайте.*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="rl-error-message w-form-fail" tabIndex="-1" role="region"*/}
                                    {/*     aria-label="tour-submission failure">*/}
                                    {/*    <div className="rl-error-text">Упс! Что-то пошло не так при отправке формы.*/}
                                    {/*        Попробуйте позже...*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        } catch (error) {
            return null;
        }

    }
}

export {SingleTourPage}

function GetTour(tour) {
    return new TourObject(
        tour['id'],
        tour['title'],
        tour['route_description'],
        tour['tour_description'],
        tour['route_image'],
    );
}

class TourObject {
    constructor(id, title, route_description, tour_description, route_image) {
        this.id = id;
        this.title = title;
        this.route_description = route_description;
        this.tour_description = tour_description;
        this.route_image = route_image;
    }
}
