import {LinksIconRow} from "./LinksIconRow";
import {Link} from "react-router-dom";
import logo from '../../assets/img/logotip.png'

const Footer = () => {
    return (
        <>
            <section>
                <div className="footer-padding">
                    <div className="footer-container">
                        <div className="footer-content-wrapper">
                            <div className="grid-footer">
                                <Link className="logo-footer" to="/">
                                    <img src={logo} width="185" height="53"/>
                                </Link>
                                <div className="grid-footer-items">
                                    <a className="footer-link-item" href="/">Главная</a>
                                    {/*<a className="footer-link-item" href="/tours">Походы</a>*/}
                                    {/*<a className="footer-link-item" href="/blog">Статьи</a>*/}
                                    <a className="footer-link-item" href="/about">О нас</a>
                                </div>
                                {/*<div className="nav_url_grid">*/}
                                {/*    <LinksIconRow/>*/}
                                {/*</div>*/}
                            </div>
                            <div>

                            </div>
                            <div className="footer-copyright">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export {Footer}