import React from "react";
import axios from "axios";

class ToursPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'tours': [],
            'all_tours': [],
            'filter': this.getParamFilter()
        };
    }

    getParamFilter = () => {
        let param = new URLSearchParams(window.location.search).get('filter')
        if (param !== null){
            return param
        } else {
            return '0'
        }
    }

    componentDidMount() {
        axios.get('https://ever-green.club/api/tours/')
            .then(res => {
                let updatedList
                if (this.state.filter !== '0'){
                    let sortedArray = [].concat(res.data)
                        .filter((tour) => tour.type === this.state.filter)
                    updatedList = sortedArray
                }else{
                    updatedList = [].concat(res.data)

                }
                document.getElementById(this.state.filter).classList.add('active-tab')
                this.setState({tours: updatedList})
                this.setState({
                    all_tours: res.data.sort((a, b) => {
                        const dateA = new Date(a.start_date.split('.').join('-'));
                        const dateB = new Date(b.start_date.split('.').join('-'));

                        return dateA.getTime() > dateB.getTime() ? 1 : -1;
                    })
                })
            })
            .catch(err => {
            });
    }

    handleClick = (e) => {
        let filterValue = e.target.id
        Array.from(document.getElementsByClassName('active-tab')).map((item, index) => {
            item.classList.remove('active-tab')
        })
        e.target.classList.add('active-tab')
        let updatedList;
        let all_tours = this.state['all_tours']
        if (filterValue !== '0'){
            let sortedArray = [].concat(all_tours)
                .filter((tour) => tour.type === filterValue)
            updatedList = sortedArray
        }else{
            updatedList = [].concat(all_tours)

        }
        this.setState({tours: updatedList})
        return () => {
            let updatedList;
            let all_tours = this.state['all_tours']
            if (filterValue !== 0){
                let sortedArray = [].concat(all_tours)
                    .filter((tour) => tour.type === filterValue)
                updatedList = sortedArray
            }else{
                updatedList = [].concat(all_tours)

            }
            this.setState({tours: updatedList})
        }
    };

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        const divs = []
        const tours = this.state['tours']
        for (let tour of tours) {
            divs.push(
                TourItem(tour['id'],tour['title'], tour['start_date'], tour['end_date'], tour['type'],
                    tour['type-title'], tour['description'], tour['price_adult'], tour['price_child_11'],
                    tour['price_child_7'], tour['main_image']
            ))
        }
        return (
            <section className="main-section-tabs">
                <div className="tabs-block">
                    <div id="allTabs" className="tabs-all">
                        <button className="tab_text text-block" id="0" onClick={this.handleClick}>
                            Все походы
                        </button>
                        <button className="tab_text text-block" id="1" onClick={this.handleClick}>
                            ПВД
                        </button>
                        <button className="tab_text text-block" id="2" onClick={this.handleClick}>
                            Праздники (Май, Июнь)
                        </button>
                        <button className="tab_text text-block" id="3" onClick={this.handleClick}>
                            Многодневные речные
                        </button>
                        <button className="tab_text text-block" id="4" onClick={this.handleClick}>
                            Лагерь-кемпинг на Селигере
                        </button>
                    </div>
                </div>
                <div className="items-tours">
                    {divs}
                </div>
            </section>
        )
    }
}

function TourItem(id, title, start, end, type, type_name, description, price_adult, price_child_11, price_child_7, main_image) {
    let main
    return (
        <div className="item-single">
            <div className="item-container">
                <div className="item-image-block">
                    <a href="#">
                        <img src={"data:image/png;base64," + main_image}/>
                    </a>
                </div>
                <div className="item-text-block">
                    <div className="block-title-item">
                        <h1 className="heading-item">{title}<br/></h1>
                        <div className="block-title-date">
                            <h1 className="heading-item">{start}</h1>
                            <h1 className="heading-item padding-mini">-</h1>
                            <h1 className="heading-item">{end}</h1>
                        </div>
                    </div>
                    <h1 className="heading-item">{type_name}</h1>
                    <div className="item-description-wrapper">
                        <div className="item-description">
                            {description}
                        </div>
                    </div>
                    <div className="item-pricing-container">
                        <div className="prices-item">
                            <div className="one-price-item">
                                <h1 className="price-heading-age">Взрослый</h1>
                                <div className="pricing-and-currency">
                                    <h4 className="price-heading-price">{price_child_11}</h4>
                                </div>
                                <div className="price-heading-currency">Руб</div>
                            </div>
                        </div>
                        <div className="prices-item">
                            <div className="one-price-item">
                                <h1 className="price-heading-age">Детский</h1>
                                <div className="pricing-and-currency">
                                    <h4 className="price-heading-price pricing-border">{price_child_11}</h4>
                                    <h4 className="price-heading-price">{price_child_7}</h4>
                                </div>
                                <div className="price-heading-currency">Руб</div>
                            </div>
                        </div>
                        <a href={`tour_page?id=${id}`} className="margin-mini">
                            <div className="btn btn-primary"> Записаться </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {ToursPage}
