import vksvg from "../../assets/img/vk.svg";
import tgsvg from "../../assets/img/tg.svg";
import ytsvg from "../../assets/img/yt.svg";

const LinksIconRow = () => {
    return (
        <div className="nav_url_grid">
            <div>
                <a href="https://vk.com/club2466184">
                    <div>
                        <img src={vksvg} alt="VK SVG" />
                    </div>
                </a>
            </div>
            <div>
                <a href="https://t.me/vechnozel">
                    <div>
                        <img src={tgsvg} alt="TG SVG" />
                    </div>
                </a>
            </div>
            <div>
                <a href="https://www.youtube.com/channel/UCoMHYmenQidc4DMPgwCwxsA">
                    <div>
                        <img src={ytsvg} alt="YT SVG" />
                    </div>
                </a>
            </div>
        </div>
    )
}

export {LinksIconRow}