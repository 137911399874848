import {IndexSlider} from "../components/IndexSlider";
import {IndexCards} from "../components/IndexCards";
import React, {useState} from "react";
import axios from "axios";

const monthNames = [
    "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
    "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
];
let direction_index = 0;

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'title': '',
            'width': 0,
            'name': '',
            'phone': '',
            'email': '',
            'message': '',
        };
    }

    componentDidMount() {
        // CalendarWidget(direction_index);
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        document.querySelector('#submitBtn').innerHTML = 'Отправляем запрос...'
        if (12 >= this.state.phone.length && this.state.phone.length >= 11 && !isNaN(this.state.phone)) {
            try {
                await axios.post('https://ever-green.club/api/question/',
                    {
                        'name': this.state.name,
                        'phone': this.state.phone,
                        'email': this.state.email,
                        'message': this.state.message,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            // Если CSRF-защита включена, добавьте заголовок CSRF-токена
                        }
                    });
            } catch (err) {
            }
            this.formSubmited(e)
        }else{
            this.formPhoneCorrection(e)
        }
    }

    formPhoneCorrection = (e) => {
        document.querySelector('#phone-input').classList.add('red-border')
        document.querySelector('#phone-format-error').style.display = ''
    }

    handleChange = (e) => {
        this.state[e.target.name] = e.target.value
    }

    formSubmited = (e) => {
        e.target.style.display = 'none'
        document.querySelector('#submitBtn').innerHTML = 'Отправить'
        document.querySelector('#afterForm').style.display = ''
    }

    restoreForm = (e) => {
        document.querySelector('#afterForm').style.display = 'none'
        let form = document.querySelector('#indexForm')
        form.style.display = ''
        Array.from(form.children).map((item, index) => {
            item.children[0].value = ''
        })

    }

    render() {
        return (
            <>
                <section className="content_section">

                    <IndexSlider/>

                    {/*<IndexCards/>*/}

                    {/*<div className="heading-calendar-container">*/}
                    {/*    <h2>Календарь походов</h2>*/}
                    {/*</div>*/}

                    {/*<section className="calendar-section" id="calendar-section">*/}
                    {/*    <div id="slider" className="slider-calendar">*/}
                    {/*    </div>*/}
                    {/*</section>*/}

                    <section>
                        <section className="position-relative py-4 py-xl-5">
                            <div className="container position-relative">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                                        <div className="card mb-5 no-border">
                                            <div className="card-body p-sm-5 form_color">
                                                <h2 className="text-center mb-4">Задайте вопрос</h2>
                                                <form method="post" onSubmit={this.handleSubmit} id="indexForm">
                                                    <div className="mb-3">
                                                        <input className="form-control" type="text" id="name-2" required
                                                               name="name" onChange={this.handleChange} placeholder="Имя"/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <input className="form-control" type="text" id="phone-input" required
                                                               name="phone" onChange={this.handleChange} placeholder="Телефон"/>
                                                        <div id="phone-format-error"
                                                             className="center-text red-text" style={{display: "none"}}>
                                                            Неверный формат номера. Пример: 79991234567
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <input className="form-control" type="email" id="email-2" required
                                                               name="email" onChange={this.handleChange} placeholder="Email"/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <textarea className="form-control" id="message-2" required
                                                                  name="message" rows="6" onChange={this.handleChange}
                                                                  placeholder="Распишите интересующие вас вопросы и ожидайте ответа по почте">
                                                        </textarea>
                                                    </div>
                                                    <div>
                                                        <button id="submitBtn" className="btn btn-primary form-button index-btn"
                                                                type="submit">Отправить
                                                        </button>
                                                    </div>
                                                </form>
                                                <div id="afterForm" style={{display: 'none'}}>
                                                    <div className="form-response">
                                                        <h5>Спасибо! В скором времени мы с вами свяжемся!</h5>
                                                        <button className="btn btn-primary form-button index-btn" onClick={this.restoreForm}>
                                                            Написать снова
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>

                </section>
            </>
        )
    }
}

export {HomePage};

// function CalendarWidget(i) {
//     let month;
//     let year = new Date().getFullYear();
//     const current_month = new Date().getMonth() + i;
//     console.log(year)
//     console.log(current_month)
//
//     if (current_month > 11) {
//         month = current_month - 12 * Math.floor(current_month/12);
//         year += Math.floor(current_month/12);
//     } else if (current_month < 0){
//         month = current_month % 12
//     }else {
//         month = current_month;
//     }
//     console.log(month)
//     ;
//     Calendar(month, year);
// };
//
//
// function Calendar(month, year) {
//     let current_year = year;
//     let current_month = month;
//     // console.log(current_year)
//     // console.log(current_month)
//     const slider = document.getElementById('slider');
//
//     let prevButton = document.createElement('button');
//     prevButton.className = 'nav-btn left-btn';
//     prevButton.textContent = '❮'
//
//     let nextButton = document.createElement('button');
//     nextButton.className = 'nav-btn right-btn';
//     nextButton.textContent = '❯'
//
//     let calendarWrapper = document.createElement('div');
//     calendarWrapper.className = 'slider-content';
//
//     prevButton.addEventListener('click', function () {
//         handleNext(-1);
//     })
//     nextButton.addEventListener('click', function () {
//         handleNext(1);
//     })
//
//     calendarWrapper = renderFrame(calendarWrapper);
//
//     slider.appendChild(calendarWrapper);
//
//     let wrapper_second = document.querySelector('#month_wrapper_1');
//
//     wrapper_second.before(prevButton);
//     wrapper_second.after(nextButton);
//
//     const previousMonth = drawMonth(current_month - 1, current_year, 0);
//     const currentMonth = drawMonth(current_month, current_year, 1);
//     const nextMonth = drawMonth(current_month + 1, current_year, 2);
//
//     fillWrappers(previousMonth, currentMonth, nextMonth);
// }
//
//
// function renderFrame(calendarWrapper) {
//     for (let i = 0; i < 3; i++) {
//         let calendarGrid = document.createElement('div');
//         calendarGrid.className = 'month_wrapper';
//         calendarGrid.id = `month_wrapper_${i}`;
//
//         calendarWrapper.appendChild(calendarGrid);
//     }
//
//     return calendarWrapper
// }
//
//
// function drawMonth(month, year, id) {
//     let monthDiv = document.createElement('div');
//     if (month === 12) {
//         year++;
//         month = 0;
//
//     } else if (month < 0) {
//         year--;
//         month = 12 + month;
//     }
//     monthDiv.className = 'month';
//     monthDiv.id = `month${id}`;
//
//     monthDiv.ariaLabel = `${monthNames[month]} ${year}`;
//     return fillMonth(month, year, monthDiv);
// }
//
//
// function fillMonth(month, year, monthDiv) {
//     let dayIndex = 0;
//     let past_days = 0;
//     let dates = getDates(month, year);
//     for (let i = 0; i < Math.floor(dates.length / 7); i++) {
//         const row = document.createElement('div');
//         row.classList.add('row_tab');
//         for (let j = 0; j < 7; j++) {
//             const cell = document.createElement('div');
//             cell.classList.add('cell_tab');
//             try {
//                 cell.textContent = dates[dayIndex];
//             } catch (error) {
//                 past_days++;
//                 cell.textContent = String(past_days);
//             }
//             row.appendChild(cell);
//             dayIndex++;
//         }
//         monthDiv.appendChild(row)
//     }
//     return monthDiv;
// }
//
//
// function getDates(month, year) {
//     const daysInMonth = new Date(year, month + 1, 0).getDate()
//     const previousMonthDays = new Date(year, month, 0).getDate()
//     const weekday_first = new Date(year, month, 1).getDay();
//     let dates_list = [];
//
//     if (weekday_first !== 1) {
//         let index;
//
//         if (weekday_first === 0) {
//             index = 5;
//         } else {
//             index = weekday_first - 2;
//         }
//         ;
//
//         for (index; index >= 0; index--) {
//             let date = previousMonthDays - index;
//             dates_list.push(date);
//         }
//     }
//     ;
//     for (let i = 1; i <= daysInMonth; i++) {
//         dates_list.push(i);
//     }
//
//     let nextDays = 0;
//     let length = dates_list.length;
//     try {
//         while (length % 7 !== 0) {
//             nextDays++;
//             length++;
//             dates_list.push(nextDays);
//         }
//
//     } catch (error) {
//         console.log(error)
//     }
//
//     return dates_list
// }
//
//
// function fillWrappers(previousMonth, currentMonth, nextMonth) {
//     let previousMonthWrapper = document.getElementById('month_wrapper_0');
//     let currentMonthWrapper = document.getElementById('month_wrapper_1');
//     let nextMonthWrapper = document.getElementById('month_wrapper_2');
//
//     let previousMonthTitle = document.createElement('h1');
//     let currentMonthTitle = document.createElement('h1');
//     let nextMonthTitle = document.createElement('h1');
//
//     previousMonthTitle.textContent = previousMonth.ariaLabel;
//     currentMonthTitle.textContent = currentMonth.ariaLabel;
//     nextMonthTitle.textContent = nextMonth.ariaLabel;
//
//     previousMonthTitle.className = 'month_title';
//     currentMonthTitle.className = 'month_title';
//     nextMonthTitle.className = 'month_title';
//
//     previousMonthWrapper.appendChild(previousMonthTitle);
//     previousMonthWrapper.appendChild(previousMonth);
//
//     currentMonthWrapper.appendChild(currentMonthTitle);
//     currentMonthWrapper.appendChild(currentMonth);
//
//     nextMonthWrapper.appendChild(nextMonthTitle);
//     nextMonthWrapper.appendChild(nextMonth);
// }
//
//
// function handleNext(direction) {
//     direction_index += direction;
//     const slider = document.getElementById('slider');
//     slider.innerHTML = '';
//     CalendarWidget(direction_index);
// }
