import {Link, Outlet} from "react-router-dom";
import {NavBar} from "./NavBar";
import {Footer} from "./Footer";

const Layout = () => {
    return(
        <>
            <NavBar/>

            <Outlet/>

            <Footer/>
        </>
    );
}

export {Layout};
