// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Slider.css */
.slider-images {
    position: relative;
    width: 100%; /* Set the width of your slider */
    height: 300px;
    overflow: hidden;
}

.slide-container {
    display: flex;
    transition: transform 0.5s ease; /* Animation effect */
}

.slide {
    min-width: 100%;
    box-sizing: border-box; /* Ensure padding and margin do not affect width */
}

.single-slide {
    position: relative;
    width: 100%;
    display: block; /* Remove bottom space for images */
    top: -150px;
    /*object-position: center;*/
    margin: auto;
    transition: all .25s ease-out;
}

.button-slide-images {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.8);
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 20px;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Slider.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,kBAAkB;IAClB,WAAW,EAAE,iCAAiC;IAC9C,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,+BAA+B,EAAE,qBAAqB;AAC1D;;AAEA;IACI,eAAe;IACf,sBAAsB,EAAE,kDAAkD;AAC9E;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,cAAc,EAAE,mCAAmC;IACnD,WAAW;IACX,2BAA2B;IAC3B,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,oCAAoC;IACpC,YAAY;IACZ,eAAe;IACf,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf","sourcesContent":["/* src/Slider.css */\n.slider-images {\n    position: relative;\n    width: 100%; /* Set the width of your slider */\n    height: 300px;\n    overflow: hidden;\n}\n\n.slide-container {\n    display: flex;\n    transition: transform 0.5s ease; /* Animation effect */\n}\n\n.slide {\n    min-width: 100%;\n    box-sizing: border-box; /* Ensure padding and margin do not affect width */\n}\n\n.single-slide {\n    position: relative;\n    width: 100%;\n    display: block; /* Remove bottom space for images */\n    top: -150px;\n    /*object-position: center;*/\n    margin: auto;\n    transition: all .25s ease-out;\n}\n\n.button-slide-images {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    background: rgba(255, 255, 255, 0.8);\n    border: none;\n    cursor: pointer;\n    padding: 10px;\n    font-size: 20px;\n}\n\n.prev {\n    left: 10px;\n}\n\n.next {\n    right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
