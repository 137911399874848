import React from "react";
import axios from "axios";

class SingleBlogPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'blog': [],
        };
    }

    componentDidMount() {
        const id = new URLSearchParams(window.location.search).get('id')
        axios.get('https://ever-green.club/api/singleblog/', {params: {'id': id}})
            .then(res => {
                this.setState({blog: res.data})
                console.log(this.state['blog'])
            })
            .catch(err => {
                console.log(err)
            });
    }

    render() {
        console.log(this.state.blog)
        return(
            <>
                <section className="blog-post-section">
                    <div className="blog-post-container">
                        <div className="blog-post-thumbnail">
                            <img src={"data:image/png;base64," + this.state.blog['thumbnail_image']}/>
                        </div>
                        <div className="blog-post-heading">
                            <h1>{this.state.blog['title']}</h1>
                        </div>
                        <div className="blog-post-content">
                            <div className="rich-text-block-blog">
                                <div dangerouslySetInnerHTML={{ __html: this.state.blog['blog_text'] }}/>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )

    }
}

export {SingleBlogPage}
