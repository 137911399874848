import image1 from "../assets/img/index_list_1.png"
import image2 from "../assets/img/index_list_2.png"
import image3 from "../assets/img/index_list_3.png"
import image4 from "../assets/img/index_list_4.png"
import exclamation from "../assets/img/exclamation1.png"
import Data from "bootstrap/js/src/dom/data";
import {Link} from "react-router-dom";


const images = [
    image1,
    image2,
    image3,
    image4,
    // { id: 2, image: image2 },
    // { id: 3, image: slide13 },
];

const IndexCards = () => {
    return (
        <section className="index_cards_section">

            <div className="index_cards_container_padding">
                <div className="index_cards_wrapper">
                    <div className="index_cards_grid">
                        <div className="index_cards_image_wrapper">
                            <img src={image1} className="index_img_cards"/>
                        </div>
                        <div className="index_cards_text_wrapper">
                            <h1>Лагерь-кемпинг на озере Селигер</h1>
                            <p>Данный формат рассчитан на широкий круг людей, но в первую очередь на семьи с детьми
                                любого возраста. Также на людей без походного опыта, и всех, кто любит отдыхать на
                                природе без постановки спортивных рекордов.</p>

                            <div className="index_cards_points">
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Кемпинг с походами налегке</p>
                                    </div>
                                </div>
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Разнообразная кухня без дежурств</p>
                                    </div>
                                </div>
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Свобода выбора сроков</p>
                                    </div>
                                </div>
                            </div>
                            <div className="index_hero_button">
                                <Link to="/tours?filter=4" className="btn btn-primary index-btn index-inner-card-btn"
                                        type="button">Подробнее
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="index_cards_container_padding">
                <div className="index_cards_wrapper">
                    <div className="index_cards_grid">
                        <div className="index_cards_text_wrapper">
                            <h1>Походы выходного дня</h1>
                            <p>Приключение на выходные! Отлично подойдёт тем, кто не имеет опыта туризма и хочет
                                попробовать, а опытным туристам подарит привычный отдых без хлопот по организации.</p>

                            <div className="index_cards_points">
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Небольшое приключение без отрыва от работы</p>
                                    </div>
                                </div>
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Идеально, чтобы попробовать походы как отдых</p>
                                    </div>
                                </div>
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Уголки красивой природы недалеко от Москвы</p>
                                    </div>
                                </div>
                            </div>
                            <div className="index_hero_button">
                                <Link to="/tours?filter=1" className="btn btn-primary index-btn index-inner-card-btn"
                                        type="button">Подробнее
                                </Link>
                            </div>
                        </div>

                        <div className="index_cards_image_wrapper grid-reverse-index">
                            <img src={image2} className="index_img_cards"/>
                        </div>

                    </div>
                </div>
            </div>

            <div className="index_cards_container_padding">
                <div className="index_cards_wrapper">
                    <div className="index_cards_grid">
                        <div className="index_cards_image_wrapper">
                            <img src={image3} className="index_img_cards"/>
                        </div>
                        <div className="index_cards_text_wrapper">
                            <h1>Многодневные речные походы</h1>
                            <p>Водный поход по реке - замечательная возможность отдохнуть от городской суеты, слиться с природой, при разумном уровне физической нагрузки.</p>

                            <div className="index_cards_points">
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Романтика дальних странствий</p>
                                    </div>
                                </div>
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Полное отключение от цивилизации и "перезагрузка"</p>
                                    </div>
                                </div>
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Комфортный темп, ни за кем не гонимся</p>
                                    </div>
                                </div>
                            </div>
                            <div className="index_hero_button">
                                <Link to="/tours?filter=3" className="btn btn-primary index-btn index-inner-card-btn"
                                        type="button">Подробнее
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="index_cards_container_padding">
                <div className="index_cards_wrapper">
                    <div className="index_cards_grid">
                        <div className="index_cards_text_wrapper">
                            <h1>Праздники: Май, Июнь</h1>
                            <p>Длинные выходные в мае и июне - идеальное время, чтобы насладиться полноводными реками, увидеть пробуждение природы после зимы, сходить в полноценный речной поход без необходимости оформлять отпуск на работе. </p>
                            <div className="index_cards_points">
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Небольшое приключение без отрыва от работы</p>
                                    </div>
                                </div>
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Интересные маршруты в заповедных уголках</p>
                                    </div>
                                </div>
                                <div className="d-xl-flex align-items-xl-center index_cards_points_wrapper">
                                    <div className="index_cards_points_img">
                                        <img
                                            src={exclamation}
                                            width="24" height="24"/>
                                    </div>
                                    <div className="index_cards_points_text">
                                        <p>Короткий, но полноценный речной поход</p>
                                    </div>
                                </div>
                            </div>
                            <div className="index_hero_button">
                                <Link to="/tours?filter=2" className="btn btn-primary index-btn index-inner-card-btn"
                                        type="button">Подробнее
                                </Link>
                            </div>
                        </div>
                        <div className="index_cards_image_wrapper grid-reverse-index">
                            <img src={image4} className="index_img_cards"/>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export {IndexCards}
